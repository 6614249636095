import loadable from '@loadable/component'
//
const TopNav = loadable(() => import('./top-nav'))
const TopNavTabButtonData = loadable(() => import('./top-nav-tab-button-data'))
TopNavTabButtonData.preload()
const TopNavProductsMenuLifecycleMenu = loadable(() =>
  import('./top-nav-products-menu-lifecycle-menu')
)
TopNavProductsMenuLifecycleMenu.preload()
const TopNavLinkItemWithSubMenuLinks = loadable(() =>
  import('./top-nav-link-item-with-sub-menu-links')
)
TopNavLinkItemWithSubMenuLinks.preload()
const TopNavProductsMenuProductsMenu = loadable(() =>
  import('./top-nav-products-menu-products-menu')
)
TopNavProductsMenuProductsMenu.preload()
const TopNavItemWithIconAndDescription = loadable(() =>
  import('./top-nav-item-with-icon-and-description')
)
TopNavItemWithIconAndDescription.preload()
const TopNavTabNavItemCards = loadable(() =>
  import('./top-nav-tab-nav-item-cards')
)
TopNavTabNavItemCards.preload()
const TopNavTabNavItemCard = loadable(() =>
  import('./top-nav-tab-nav-item-card')
)
TopNavTabNavItemCard.preload()
const TopNavLeftSidebarMenu = loadable(() =>
  import('./top-nav-left-sidebar-menu')
)
TopNavLeftSidebarMenu.preload()
const TopNavLeftSidebarItemWithDescMenuLinks = loadable(() =>
  import('./top-nav-left-sidebar-item-with-desc-menu-links')
)
const MarketoFormEmbedModule = loadable(() =>
  import('./marketo-form-embed-module')
)
MarketoFormEmbedModule.preload()

TopNavLeftSidebarItemWithDescMenuLinks.preload()

const Box = loadable(() => import('./box'))
const Button = loadable(() => import('./button'))
const ComponentNotFound = loadable(() => import('./component-not-found'))
const Feature = loadable(() => import('./feature'))
const Grid = loadable(() => import('./grid'))
const Header = loadable(() => import('./header'))
const Image = loadable(() => import('./image'))
const Link = loadable(() => import('./link'))
const Markdown = loadable(() => import('./markdown'))
const Page = loadable(() => import('./page'))
const Skeleton = loadable(() => import('./skeleton'))
const Stat = loadable(() => import('./stat'))
const Teaser = loadable(() => import('./teaser'))
const Typography = loadable(() => import('./typography'))
const ProductsIntroModule = loadable(() => import('./products-intro-module'))
const ProductCard = loadable(() => import('./product-card'))
const HeroCarousel = loadable(() => import('./hero-carousel'))
const HeroFrame = loadable(() => import('./hero-frame'))
const FeaturedResourcesModule = loadable(() =>
  import('./featured-resources-module')
)
const BlogCard = loadable(() => import('./blog-card'))
const ResourceCard = loadable(() => import('./resource-card'))
const CustomerLogosModule = loadable(() => import('./customer-logos-module'))
const ProductDetailsIntroModule = loadable(() =>
  import('./product-details-intro-module')
)
const TwoColHighlightsWithImage = loadable(() =>
  import('./two-col-highlights-with-image')
)
const CtaBannerModule = loadable(() => import('./cta-banner-module'))
const HeaderModule = loadable(() => import('./header-module'))
const ModuleHeadlineWith4ColumnStats = loadable(() =>
  import('./module-headline-with-4-column-stats')
)
const StatWithSubtitleAndLink = loadable(() =>
  import('./stat-with-subtitle-and-link')
)
const StatWithSubtitle = loadable(() => import('./stat-with-subtitle'))
const QuoteModule = loadable(() => import('./quote-module'))
const HeadlineWith4ColModule = loadable(() =>
  import('./headline-with-4-col-module')
)
const ImageTitleDescriptionCard = loadable(() =>
  import('./image-title-description-card')
)
const TitleSubtitleDescriptionModule = loadable(() =>
  import('./title-subtitle-description-module')
)
const LifecyclePhasesModule = loadable(() =>
  import('./lifecycle-phases-module')
)
const PhaseCard = loadable(() => import('./phase-card'))
const TitleWithImageModule = loadable(() => import('./title-with-image-module'))
const DetailsPage = loadable(() => import('./details-page'))
const VisionModule = loadable(() => import('./vision-module'))
const SolutionsMenuList = loadable(() => import('./solutions-menu-list'))
const LifecycleModule = loadable(() => import('./lifecycle-module'))
const HeadlineDescriptionWithCta = loadable(() =>
  import('./headline-description-with-cta')
)
const GlobalPresenceModule = loadable(() => import('./global-presence-module'))
const MarketoFormModule = loadable(() =>
  import('./marketo-form-module/marketo-form-module')
)
const ContactUsBodyModule = loadable(() => import('./contact-us-body-module'))
const ContactUsModule = loadable(() => import('./contact-us-module'))
const ContactNumber = loadable(() => import('./contact-number'))
const ContactCard = loadable(() => import('./contact-card'))
const GalleryModule = loadable(() => import('./gallery-module'))
const GalleryFiltersMenuModule = loadable(() =>
  import('./gallery-filters-menu-module')
)
const GalleryFilterModule = loadable(() => import('./gallery-filter-module'))
const CtaWithOverlayModule = loadable(() => import('./cta-with-overlay-module'))
const StatsModule = loadable(() => import('./stats-module'))
const LongFormTextModule = loadable(() => import('./long-form-text-module'))
const ImagesModule = loadable(() => import('./images-module'))
const WistiaVideoModule = loadable(() => import('./wistia-video-module'))
const MarkdownModule = loadable(() => import('./markdown-module'))
const BottomNav = loadable(() => import('./bottom-nav'))
const BottomNavMenu = loadable(() => import('./bottom-nav-menu'))
const Text = loadable(() => import('./text'))
const WistiaLink = loadable(() => import('./wistia-link'))
const Spacing = loadable(() => import('./spacing'))
const MarketoFormCheckbox = loadable(() => import('./marketo-form-checkbox'))
const TabCarousel = loadable(() => import('./tab-carousel'))
const VideoGallery = loadable(() => import('./video-gallery'))
const VideoThumbnail = loadable(() => import('./video-thumbnail'))
const FeaturedVideo = loadable(() => import('./featured-video'))
const CardModule = loadable(() => import('./card-module'))
const StickyContainer = loadable(() => import('./sticky-container'))
const IntegrationFilter = loadable(() => import('./integration-filter'))
const TitleDescriptionWithLogoAndSection = loadable(() =>
  import('./title-description-with-logo-and-section')
)
const CtaModule = loadable(() => import('./cta-module'))
const MarketoFormMultiSelect = loadable(() =>
  import('./marketo-form-multi-select')
)
const MarketoFormText = loadable(() => import('./marketo-form-text'))
const MarketoFormTextarea = loadable(() => import('./marketo-form-textarea'))
const MarketoFormSelect = loadable(() => import('./marketo-form-select'))
const WebinarSeries = loadable(() => import('./webinar-series'))
const ResourceDetail = loadable(() => import('./resource-detail'))
const ResourceReferences = loadable(() => import('./resource-references'))
const ResourcesIndex = loadable(() => import('./resources-index'))
const MultiSelectFilter = loadable(() => import('./multi-select-filter'))
const SingleSelectFilter = loadable(() => import('./single-select-filter'))
const SearchFilter = loadable(() => import('./search-filter'))
const ResourcesFilter = loadable(() => import('./resources-filter'))
const BlogReferences = loadable(() => import('./blog-references'))
const TabsModule = loadable(() => import('./tabs-module'))
const Tab = loadable(() => import('./tab'))
const DeviceFrameFeatureModule = loadable(() =>
  import('./device-frame-module/device-frame-feature-module')
)
const DeviceFrameFeature = loadable(() =>
  import('./device-frame-module/device-frame-feature')
)
const InteractiveQuizModule = loadable(() =>
  import('./interactive-quiz-module')
)
const InteractiveQuizQuestion = loadable(() =>
  import('./interactive-quiz-question')
)
const InteractiveQuizResult = loadable(() =>
  import('./interactive-quiz-result')
)
const WebinarsIndex = loadable(() => import('./webinars-index'))
const SpeakerCard = loadable(() => import('./speaker-card'))
const HorizontalRule = loadable(() => import('./horizontal-rule'))
const AnnouncementCard = loadable(() => import('./announcement-card'))
const ConnectedTwoColumnHighlights = loadable(() =>
  import('./connected-two-column-highlights')
)
const EventDetail = loadable(() => import('./event-detail'))
const EventsIndex = loadable(() => import('./events-index'))
const EventReferences = loadable(() => import('./event-references'))
const EventsFilter = loadable(() => import('./events-filter.js'))
const InstructionWithCTA = loadable(() => import('./instruction-with-cta'))
const SectionHeading = loadable(() => import('./section-heading'))
const CardGalleryModule = loadable(() => import('./card-gallery-module'))
const UnifiedProductCard = loadable(() => import('./unified-product-card'))
const TrialProductsModule = loadable(() => import('./trial-products-module'))
const ProductLogo = loadable(() => import('./product-logo'))
const PricingCardsModule = loadable(() => import('./pricing-cards-module'))
const PricingCard = loadable(() => import('./pricing-card'))
const TwoColumnLayoutModule = loadable(() =>
  import('./two-column-layout-module')
)
const Checklist = loadable(() => import('./checklist'))
const ChecklistItem = loadable(() => import('./checklist-item'))
const IconHighlight = loadable(() => import('./iconHighlight'))
const AccordionModule = loadable(() => import('./accordion-module'))
const AccordionSection = loadable(() => import('./accordion-section'))
const SubNav = loadable(() => import('./sub-nav'))
const ShowcaseModule = loadable(() => import('./showcase-module'))

const PlangridTransitionModal = loadable(() =>
  import('./plangrid-transition-modal')
)
const FormModal = loadable(() => import('./form-modal'))
const BannerPanel = loadable(() => import('./banner-panel'))
const AssetDownloadBanner = loadable(() => import('./asset-download-banner'))
const FullScreenHero = loadable(() => import('./full-screen-hero'))
const HeadlineDescriptionWithImagesAndCTA = loadable(() =>
  import('./headline-description-with-image-and-cta')
)
const SimpleCard = loadable(() => import('./simple-card'))
const GeoBanner = loadable(() => import('./geo-banner'))
const PageNotFound = loadable(() => import('./404-module'))
const TableMatrix = loadable(() => import('./table-matrix'))
const TableMatrixRow = loadable(() => import('./table-row'))
const TableMatrixCell = loadable(() => import('./table-cell'))
const WistiaAutoplayPopup = loadable(() => import('./wistia-autoplay-popup'))
const MeetupsIndex = loadable(() => import('./meetups-index'))
const MeetupTopic = loadable(() => import('./meetup-topic'))
const MeetupDetail = loadable(() => import('./meetup-detail'))
const CtaModulePageContext = loadable(() => import('./cta-module-page-context'))
const MeetupTopicCard = loadable(() => import('./meetup-topic-card'))
const VideoPlaylist = loadable(() => import('./video-playlist'))
const WistiaVideoPlaylistItem = loadable(() =>
  import('./wistia-video-playlist-item')
)
const ReviewCard = loadable(() => import('./review-card'))
const RoadmapBlock = loadable(() => import('./roadmap-block'))
const VideoCtaBanner = loadable(() => import('./video-cta-banner'))
const Icon = loadable(() => import('./icon'))
const PromoBar = loadable(() => import('./promo-bar'))
const ChannelPartnersindex = loadable(() => import('./channel-partners-index'))
const ChannelPartner = loadable(() => import('./channel-partner'))
const FaqModule = loadable(() => import('./faq-module'))
const GoogleStructuredData = loadable(() => import('./google-structured-data'))
const GatedContentModule = loadable(() => import('./gated-content-module'))
const VideoCard = loadable(() => import('./video-card'))
const Highlight = loadable(() => import('./highlight'))
const CalendarModule = loadable(() => import('./calendar-module'))
const CalendarEvent = loadable(() => import('./calendar-event'))
const ABTest = loadable(() => import('./ab-test'))
const ABTestVariation = loadable(() => import('./ab-test-variation'))
const Tooltip = loadable(() => import('./tooltip'))
const InteractiveFormModule = loadable(() =>
  import('./interactive-form-module')
)
const InteractiveFormCardSelect = loadable(() =>
  import('./interactive-form-card-select')
)
const InteractiveFormGroup = loadable(() => import('./interactive-form-group'))
const InteractiveFormCardSelectOption = loadable(() =>
  import('./interactive-form-card-select-option')
)
const WebinarSeriesEvent = loadable(() => import('./webinar-series-event'))
const NavatticModule = loadable(() => import('./navattic-module'))
const QuoteCarousel = loadable(() => import('./quote-carousel'))
const ChampionsDetail = loadable(() => import('./champions-detail'))
const ChampionsIndex = loadable(() => import('./champions-index'))
const SocialLink = loadable(() => import('./social-link'))
const LabelledLink = loadable(() => import('./labelled-link'))
const ModuleReferencesModule = loadable(() =>
  import('./module-references-module')
)
const Video = loadable(() => import('./video'))

const ComponentList = {
  page: Page,
  grid: Grid,
  teaser: Teaser,
  feature: Feature,
  button: Button,
  image: Image,
  link: Link,
  markdown: Markdown,
  stat: Stat,
  skeleton: Skeleton,
  box: Box,
  typography: Typography,
  header: Header,
  topNav: TopNav,
  moduleHeadlineWith4ColumnStats: ModuleHeadlineWith4ColumnStats,
  statWithSubtitleAndLink: StatWithSubtitleAndLink,
  statWithSubtitle: StatWithSubtitle,
  productsIntroModule: ProductsIntroModule,
  productCard: ProductCard,
  headerModule: HeaderModule,
  productDetailsIntroModule: ProductDetailsIntroModule,
  heroCarousel: HeroCarousel,
  heroFrame: HeroFrame,
  featuredResourcesModule: FeaturedResourcesModule,
  resourceCard: ResourceCard,
  blogCard: BlogCard,
  customerLogosModule: CustomerLogosModule,
  twoColHighlightsWithImage: TwoColHighlightsWithImage,
  ctaBannerModule: CtaBannerModule,
  titleSubtitleDescriptionModule: TitleSubtitleDescriptionModule,
  headlineWith4ColModule: HeadlineWith4ColModule,
  imageTitleDescriptionCard: ImageTitleDescriptionCard,
  quoteModule: QuoteModule,
  lifecyclePhasesModule: LifecyclePhasesModule,
  phaseCard: PhaseCard,
  titleWithImageModule: TitleWithImageModule,
  detailsPage: DetailsPage,
  visionModule: VisionModule,
  solutionsMenuList: SolutionsMenuList,
  lifecycleModule: LifecycleModule,
  headlineDescriptionWithCta: HeadlineDescriptionWithCta,
  globalPresenceModule: GlobalPresenceModule,
  topNavTabButtonData: TopNavTabButtonData,
  topNavProductsMenuLifecycleMenu: TopNavProductsMenuLifecycleMenu,
  topNavLinkItemWithSubMenuLinks: TopNavLinkItemWithSubMenuLinks,
  topNavProductsMenuProductsMenu: TopNavProductsMenuProductsMenu,
  topNavItemWithIconAndDescription: TopNavItemWithIconAndDescription,
  topNavTabNavItemCards: TopNavTabNavItemCards,
  topNavTabNavItemCard: TopNavTabNavItemCard,
  marketoFormModule: MarketoFormModule,
  contactUsBodyModule: ContactUsBodyModule,
  contactUsModule: ContactUsModule,
  contactNumber: ContactNumber,
  contactCard: ContactCard,
  statsModule: StatsModule,
  ctaWithOverlayModule: CtaWithOverlayModule,
  longFormTextModule: LongFormTextModule,
  imagesModule: ImagesModule,
  wistiaVideoModule: WistiaVideoModule,
  galleryModule: GalleryModule,
  galleryFiltersMenuModule: GalleryFiltersMenuModule,
  galleryFilterModule: GalleryFilterModule,
  markdownModule: MarkdownModule,
  bottomNav: BottomNav,
  bottomNavMenu: BottomNavMenu,
  text: Text,
  cardModule: CardModule,
  wistiaLink: WistiaLink,
  spacing: Spacing,
  marketoFormCheckbox: MarketoFormCheckbox,
  titleDescriptionWithLogoAndSection: TitleDescriptionWithLogoAndSection,
  stickyContainer: StickyContainer,
  ctaModule: CtaModule,
  tabCarousel: TabCarousel,
  tabsModule: TabsModule,
  tab: Tab,
  videoGallery: VideoGallery,
  videoThumbnail: VideoThumbnail,
  featuredVideo: FeaturedVideo,
  integrationFilter: IntegrationFilter,
  marketoFormMultiSelect: MarketoFormMultiSelect,
  marketoFormText: MarketoFormText,
  marketoFormTextarea: MarketoFormTextarea,
  marketoFormSelect: MarketoFormSelect,
  webinarSeries: WebinarSeries,
  resourceDetail: ResourceDetail,
  resourceReferences: ResourceReferences,
  resourcesIndex: ResourcesIndex,
  multiSelectFilter: MultiSelectFilter,
  singleSelectFilter: SingleSelectFilter,
  searchFilter: SearchFilter,
  resourcesFilter: ResourcesFilter,
  blogReferences: BlogReferences,
  deviceFrameFeatureModule: DeviceFrameFeatureModule,
  deviceFrameFeature: DeviceFrameFeature,
  interactiveQuizModule: InteractiveQuizModule,
  interactiveQuizQuestion: InteractiveQuizQuestion,
  interactiveQuizResult: InteractiveQuizResult,
  webinarsIndex: WebinarsIndex,
  speakerCard: SpeakerCard,
  horizontalRule: HorizontalRule,
  announcementCard: AnnouncementCard,
  connectedTwoColumnHighlights: ConnectedTwoColumnHighlights,
  eventDetail: EventDetail,
  eventsIndex: EventsIndex,
  eventReferences: EventReferences,
  eventsFilter: EventsFilter,
  instructionWithCta: InstructionWithCTA,
  topNavLeftSidebarMenu: TopNavLeftSidebarMenu,
  topNavLeftSidebarItemWithDescMenuLinks:
    TopNavLeftSidebarItemWithDescMenuLinks,
  sectionHeading: SectionHeading,
  cardGalleryModule: CardGalleryModule,
  unifiedProductCard: UnifiedProductCard,
  trialProductsModule: TrialProductsModule,
  productLogo: ProductLogo,
  pricingCardsModule: PricingCardsModule,
  pricingCard: PricingCard,
  twoColumnLayoutModule: TwoColumnLayoutModule,
  checklist: Checklist,
  checklistItem: ChecklistItem,
  iconHighlight: IconHighlight,
  accordionModule: AccordionModule,
  accordionSection: AccordionSection,
  subNav: SubNav,
  showcaseModule: ShowcaseModule,
  marketoFormEmbedModule: MarketoFormEmbedModule,
  plangridTransitionModal: PlangridTransitionModal,
  channelPartnersIndex: ChannelPartnersindex,
  channelPartner: ChannelPartner,
  formModal: FormModal,
  bannerPanel: BannerPanel,
  assetDownloadBanner: AssetDownloadBanner,
  fullScreenHero: FullScreenHero,
  headlineDescriptionWithImagesAndCta: HeadlineDescriptionWithImagesAndCTA,
  simpleCard: SimpleCard,
  geoBanner: GeoBanner,
  PageNotFound404Module: PageNotFound,
  tableMatrix: TableMatrix,
  row: TableMatrixRow,
  cell: TableMatrixCell,
  wistiaAutoplayPopup: WistiaAutoplayPopup,
  meetupsIndex: MeetupsIndex,
  meetupTopic: MeetupTopic,
  meetupDetail: MeetupDetail,
  ctaModulePageContext: CtaModulePageContext,
  meetupTopicCard: MeetupTopicCard,
  videoPlaylist: VideoPlaylist,
  wistiaVideoPlaylistItem: WistiaVideoPlaylistItem,
  reviewCard: ReviewCard,
  roadmapBlock: RoadmapBlock,
  videoCtaBanner: VideoCtaBanner,
  icon: Icon,
  promoBar: PromoBar,
  faqModule: FaqModule,
  googleStructuredData: GoogleStructuredData,
  gatedContentModule: GatedContentModule,
  videoCard: VideoCard,
  highlight: Highlight,
  calendarModule: CalendarModule,
  calendarEvent: CalendarEvent,
  abTest: ABTest,
  abTestVariation: ABTestVariation,
  tooltip: Tooltip,
  interactiveFormModule: InteractiveFormModule,
  interactiveFormCardSelect: InteractiveFormCardSelect,
  interactiveFormGroup: InteractiveFormGroup,
  interactiveFormCardSelectOption: InteractiveFormCardSelectOption,
  webinarSeriesEvent: WebinarSeriesEvent,
  navatticModule: NavatticModule,
  quoteCarousel: QuoteCarousel,
  championsDetail: ChampionsDetail,
  championsIndex: ChampionsIndex,
  socialLink: SocialLink,
  labelledLink: LabelledLink,
  moduleReferencesModule: ModuleReferencesModule,
  video: Video,
}

const BlokComponents = (type) => {
  if (typeof ComponentList[type] === 'undefined') {
    return ComponentNotFound
  }
  return ComponentList[type]
}

export default BlokComponents
